<template>
  <section>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Escolas" to="/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #ff7f27"
            >Presença da turma</span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>

      <h2 class="titulo-links-escolha">Presença da Turma</h2>
      
      <div class="col-md-12">
          <div class="form-group col-md-6">
            <label>Selecione um Mês:</label>
            <select class="form-control" v-model="mes" @change="buscarMes()">
                <option  value="1" >Janeiro</option>
                <option  value="2" >Fevereiro</option>
                <option  value="3" >Março</option>
                <option  value="4" >Abril</option>
                <option  value="5" >Maio</option>
                <option  value="6" >Junho</option>
                <option  value="7" >Julho</option>
                <option  value="8" >Agosto</option>
                <option  value="9" >Setembro</option>
                <option  value="10" >Outubro</option>
                <option  value="12" >Novembro</option>
                <option  value="12" >Dezembro</option>
            </select>
          </div>
      </div> 

      <div class="col-md-12">
          <div class="form-group col-md-6" style="margin-top:15px;">
            <label>Selecione uma Disciplina:</label>
            <select class="form-control" v-model="disciplina_id" @change="buscarDisciplina()">
                <option  :value="n.id" v-for="n in disciplina" :key="n">{{n.nome}}</option>
            </select>
          </div>
      </div>       

      <br><br>

      <va-data-table
          v-if="presenca.length"
          striped="true"
          hoverable="true"
          animated="true"
          :items="presenca"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
        >
        <template #cell(id)="{ source: id }">
          <va-button class="mr-2 mb-2" size="small" color="success" @click="mostrar_presenca(id)"> {{ $t('Presenças') }}</va-button>
        </template> 
  
        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>

      <div class="col-md-12" v-else>
        Ainda não existe presença nessa Turma!
      </div>  
    </section>
  </section>
</template>


<script>
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Presenca } from "@/class/presenca";
import { DisciplinaEscolar } from "@/class/disciplina";

export default defineComponent({
  props:{
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
  },
  data () {
    const columns = [
      { key: "aluno", label: "Nome", sortable: true },
      { key: "ano", label: "Ano", sortable: true },
      { key: 'id', label:'Ação'},
    ];
    return {
      columns,
      mes:1,
      presenca: [],
      disciplina:[],
      disciplina_id: null,
    }
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.presenca.length / this.perPage)
        : this.presenca.length;
    },
  },
  methods:{
    async buscarDisciplina(){
      let payload = {
        escola_id: this.escola_id,
        turma_id: this.turma_id,
        mes: this.mes,
        disciplina_id: this.disciplina_id,
      };

      let data = await Presenca.buscarPresencaTurmaConfig(payload);
      this.presenca = [];
      this.presenca = data.data;
    },
    async buscarMes(){
      let payload = {
        escola_id: this.escola_id,
        turma_id: this.turma_id,
        mes: this.mes,
      };

      let data = await Presenca.buscarPresencaTurmaConfig(payload);
      this.presenca = [];
      this.presenca = data.data;
    },
    async listarPresenca() {
      let payload = {
        escola_id: this.escola_id,
        turma_id: this.turma_id,
      };

      let data = await Presenca.buscarPresencaTurmaConfig(payload);
      this.presenca = [];
      this.presenca = data.data;
    },
    async listaDisciplina(){
      let data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma_id);
     
      for (const el of data.data) {
        if(el.na_turma == 1){
          let novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplina.push(novo);
        }
      }
    }
  },
  beforeMount() {
    this.listarPresenca();
    this.listaDisciplina();
  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>
